import getEventHandlerResize from '../ResizeHandler';
import { hasClass, removeLazyLoadingAndExecute } from '../../tools/helpers';

let desktop = false;

const viewportSwitch = (currentViewport) => {
  switch (currentViewport) {
    case 'XS':
    case 'MS':
    case 'SM':
      return false;
    default:
      return true;
  }
};

class LinkWithAnchorHandler {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    this.element.addEventListener('click', (event) => {
      event.preventDefault();
      removeLazyLoadingAndExecute(this.scrollToAnchor.bind(this));
    });
  }

  scrollToAnchor() {
    const hrefValue = CSS.escape(this.element.getAttribute('href'));
    const hashIndex = hrefValue.indexOf('#');
    let targetId = '';

    if (hashIndex !== -1) {
      // extract all after the `#`
      targetId = hrefValue.substring(hashIndex + 1);
    }

    const targetElement = document.querySelector(`#${targetId}`);
    const offsetPositionAdjustmentSmallHeader = 95;
    const offsetPositionAdjustmentBigHeader = 231;
    this.headerHeight = document.getElementById('header').offsetHeight;

    if (targetElement) {
      if (this.headerHeight <= 91) {
        // eslint-disable-next-line max-len
        this.targetPosition = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop - offsetPositionAdjustmentSmallHeader;
      } else {
        // eslint-disable-next-line max-len
        this.targetPosition = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop - offsetPositionAdjustmentBigHeader;
      }

      let offsetPosition = this.targetPosition;

      desktop = viewportSwitch(getEventHandlerResize().getCurrentViewport());

      if (!desktop && hasClass(document.getElementById('header'), 'affix-top')) {
        offsetPosition -= 90;
      }

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }
}
export default LinkWithAnchorHandler;

import getEventHandlerResize from '../ResizeHandler';
import { CONFIG } from '../config';
import { addClass, removeClass, removeLazyLoadingAndExecute } from '../../tools/helpers';

const OFFSET_POSITION_ADJUSTMENT = 150;
let desktop = false;

const viewportSwitch = (currentViewport) => {
  switch (currentViewport) {
    case 'XS':
    case 'MS':
    case 'SM':
      return false;
    default:
      return true;
  }
};

class ContentHubArticleStickyIndex {

  constructor(options = {}) {
    this.element = options.element;
    this.headerHeight = document.getElementById('header').offsetHeight;
    this.STICCKY_LINKS = this.element.querySelectorAll('.mol--article-index-sticky__list li a');
    this.ARTICLE_INDEX_CONTAINER = document.querySelector('.mol--article__index');
    this.ARTICLE_ANCHORS = document.querySelectorAll('.page-anchor');
    this.currentActiveLink = null;
    this.clickedLink = false;

    this.slider = document.querySelector('[data-slider]');
    this.track = this.slider.querySelector('[data-slider-track]');
    this.prevButton = this.slider.querySelector('[data-slider-prev]');
    this.nextButton = this.slider.querySelector('[data-slider-next]');
  }

  init() {
    window.addEventListener(CONFIG.events.viewportChange, () => {
      desktop = viewportSwitch(getEventHandlerResize().getCurrentViewport());
      addClass(this.element, 'hidden');
      this.showStickyLinks();
      this.scrollSnapStickyLinks();
    });

    this.resetScrollPosition();
    this.showStickyLinks();
    this.scrollToAnchor();
    this.scrollSnapStickyLinks();
  }

  resetScrollPosition() {
    // eslint-disable-next-line no-restricted-globals
    history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
  }

  showStickyLinks() {
    /* If there are no anchors on the page then the sticky-links will not display */
    if (this.ARTICLE_ANCHORS.length === 0) {
      return;
    }

    /* mobil show sticky-links allways  */
    desktop = viewportSwitch(getEventHandlerResize().getCurrentViewport());
    if (!desktop) {
      removeClass(this.element, 'hidden');
    }

    window.addEventListener('scroll', () => {
      // eslint-disable-next-line max-len
      const articleIndexContainerBottom = this.ARTICLE_INDEX_CONTAINER.getBoundingClientRect().bottom - this.headerHeight;

      // set timout for no flicker in the sticky when a link in the article index is clicked (see ContenHubArticleIndex)
      // eslint-disable-next-line no-restricted-globals
      setTimeout(() => {

        if (articleIndexContainerBottom < 0) { // Wenn der untere Rand der UL oben aus dem Sichtbereich verschwindet
          removeClass(this.element, 'hidden');
        } else if (!desktop) {
          removeClass(this.element, 'hidden');
          this.STICCKY_LINKS.forEach((link) => {
            removeClass(link, 'active');
          });
        } else {
          addClass(this.element, 'hidden');
        }

        this.updateActiveLinkOnScroll();

      }, 100);
    });
  }

  updateActiveLinkOnScroll() {
    this.ARTICLE_ANCHORS.forEach((articleAnchor, index) => {
      if (this.isElementVisible(articleAnchor, 300)) {
        const newActiveLink = this.STICCKY_LINKS[index];
        if (this.currentActiveLink !== newActiveLink) {
          this.STICCKY_LINKS.forEach(link => removeClass(link, 'active'));
          addClass(newActiveLink, 'active');
          this.currentActiveLink = newActiveLink;

          this.scrollToActiveLink(this.currentActiveLink);
        }
      }
    });
  }

  scrollToActiveLink(currentActiveLink) {
    if (this.clickedLink) {
      this.clickedLink = false;
      return;
    }

    if (currentActiveLink) {
      // eslint-disable-next-line max-len
      const activeLinkPosition = currentActiveLink.getBoundingClientRect().left - this.track.getBoundingClientRect().left;
      this.track.scrollTo({
        left: activeLinkPosition,
        behavior: 'smooth',
      });
    }
  }

  isElementVisible(element, maxDistance) {
    const rect = element.getBoundingClientRect();
    return (rect.top >= 0 && rect.top <= maxDistance);
  }

  scrollToAnchor() {
    this.STICCKY_LINKS.forEach((anchor) => {
      anchor.addEventListener('click', (event) => {
        event.preventDefault();
        this.clickedLink = true;

        removeLazyLoadingAndExecute(() => {
          const targetId = CSS.escape(anchor.getAttribute('href').substring(1));
          const targetElement = document.querySelector(`#${targetId}`);
          if (targetElement) {
            const targetPosition = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop;
            const offsetPosition = targetPosition - OFFSET_POSITION_ADJUSTMENT;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        });
      });
    });
  }


  scrollSnapStickyLinks() {
    if (this.track) {

      const trackScrollWidth = this.track.scrollWidth;
      const trackOuterWidth = this.track.clientWidth;

      if (trackOuterWidth - trackScrollWidth < 0) {
        removeClass(this.nextButton, 'inactive');
        addClass(this.track, 'scrollable');
      }

      this.prevButton.addEventListener('click', () => {
        this.track.scrollTo({
          left: this.track.scrollLeft - this.track.firstElementChild.offsetWidth,
          behavior: 'smooth',
        });
      });

      this.nextButton.addEventListener('click', () => {
        this.track.scrollTo({
          left: this.track.scrollLeft + this.track.firstElementChild.offsetWidth,
          behavior: 'smooth',
        });
      });

      this.track.addEventListener('scroll', () => {
        removeClass(this.prevButton, 'inactive');
        removeClass(this.nextButton, 'inactive');

        if (this.track.scrollLeft <= 0) {
          addClass(this.prevButton, 'inactive');
          addClass(this.track, 'scrollable');
        }

        if (this.track.scrollLeft >= (trackScrollWidth - trackOuterWidth)) {
          addClass(this.nextButton, 'inactive');
          removeClass(this.track, 'scrollable');
        }
      });
    }
  }
}

export default ContentHubArticleStickyIndex;

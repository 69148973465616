import { removeLazyLoadingAndExecute } from '../../tools/helpers';

class ContentHubArticleIndex {

  constructor(options = {}) {
    this.element = options.element;
    this.INDEX_LINKS = this.element.querySelectorAll('li a');
  }

  init() {
    this.scrollToAnchor();
  }

  scrollToAnchor() {
    this.INDEX_LINKS.forEach((anchor) => {
      anchor.addEventListener('click', (event) => {
        event.preventDefault();

        removeLazyLoadingAndExecute(() => {
          const targetId = CSS.escape(anchor.getAttribute('href').substring(1));
          const targetElement = document.querySelector(`#${targetId}`);
          const offsetPositionAdjustmentSmallHeader = 80;
          const offsetPositionAdjustmentBigHeader = 216;
          const headerHeight = document.getElementById('header').offsetHeight;

          if (targetElement) {
            let targetPosition;

            if (headerHeight <= 91) {
              // eslint-disable-next-line max-len
              targetPosition = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop - offsetPositionAdjustmentSmallHeader;
            } else {
              // eslint-disable-next-line max-len
              targetPosition = targetElement.getBoundingClientRect().top + document.documentElement.scrollTop - offsetPositionAdjustmentBigHeader;
            }

            window.scrollTo({
              top: targetPosition,
              behavior: 'smooth',
            });
          }
        });
      });
    });
  }
}

export default ContentHubArticleIndex;

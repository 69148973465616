import { CONFIG } from '../config';
import { addClass, findParent, removeClass } from '../../tools/helpers';
import ConsentManager from '../ConsentManager';
import TemplateHandler from '../TemplateHandler';
import getEventHandlerResize from '../ResizeHandler';

const getViewportSource = (element) => {
  const viewport = getEventHandlerResize().getCurrentViewport();
  let size = '';

  switch (viewport) {
    case ('XS'):
    case ('MS'):
      size = 'small';
      break;
    case ('SM'):
    case ('MD'):
      size = 'medium';
      break;
    case ('LG'):
    case ('XL'):
    case ('XXL'):
    default:
      size = 'large';
  }

  const urlForSize = element.getAttribute(`data-${size}`);
  const useFallback = urlForSize == null || urlForSize.length > 0

  return useFallback ? element.getAttribute('data-large').toString() : element.getAttribute(`data-${size}`).toString();
};

class ConsentMedia {
  constructor(options = {}) {
    this.element = options.element;
    this.parent = findParent(this.element, 'consent-media');
    this.consentNeeded = true;
    this.consentContent = TemplateHandler.getTemplate('consent-overlay-content', this.element);
    this.mediaContent = getViewportSource(this.parent.querySelector('video'));
  }

  init() {
    this.initConsentManager();
  }

  initConsentManager() {
    if (this.consentNeeded) {
      this.consentManager = new ConsentManager({
        element: this.element,
        consentTemplateId: this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.templateId,
        consentDataProcessor: this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.processor,
        callbackFail: () => this.insertConsent(),
        callbackSuccess: () => this.insertContent(),
        callbackReinit: () => this.init(),
      });

      this.consentManager.init();
    }
  }

  insertConsent() {
    const content = document.createElement('section');
    addClass(content, 'consent__slot');
    content.insertAdjacentHTML('beforeend', this.consentContent);

    if (!this.parent.querySelector('.consent__wrapper')) {
      this.parent.appendChild(content);
      this.addLayerEvents();
    }
  }

  insertContent() {
    addClass(this.parent.querySelector('.mol--media-teaser__placeholder-image'), 'hidden');

    if (this.parent.querySelector('.consent__slot')) {
      this.parent.removeChild(this.parent.querySelector('.consent__slot'));
    }

    this.renderVideo();
    this.addResizeEvents();
  }

  addLayerEvents() {
    const buttonAccept = this.parent.querySelector('.give-consent');
    if (buttonAccept !== null) {
      buttonAccept.addEventListener('click', () => {
        this.consentManager.giveConsent();
      });
    }

    const buttonInfo = this.parent.querySelector('.more-info');
    if (buttonInfo !== null) {
      const templateId = this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.templateId;
      buttonInfo.addEventListener('click', () => {
        this.consentManager.toggleConsentWindow(templateId);
      });
    }
  }

  addResizeEvents() {
    window.addEventListener(CONFIG.events.viewportChange, () => {
      this.renderVideo();
    });
  }

  renderVideo() {
    const video = this.parent.querySelector('video');
    removeClass(video, 'hidden');
    this.mediaContent = getViewportSource(video);

    video.setAttribute('src', this.mediaContent);
  }
}

export default ConsentMedia;
